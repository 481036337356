import { css } from '@emotion/react';
import { memo } from 'react';
import { AppLink } from '~/components/AppLink';
import { createDesktopCss } from '~/css/createDesktopCss';
import { fontWeight600 } from '~/css/font';
import { baseNoLinkCss } from '~/css/noLinkCss';
import useMedia from '~/hooks/useMedia';
import { flex } from '~/modules/AppLayout/FlexGridCss';
export const Footer = memo(function Footer() {
    const { isPc } = useMedia();
    return (<div css={css `
        ${flex.v.allCenter};
        margin: ${isPc ? 'auto' : '0 16px'};
        padding: ${isPc ? '78px 0 24px' : '24px 0'};
        color: white;
      `}>
      <div css={css `
          ${isPc ? flex.h.mainCenter : flex.v.default};
          & > * {
            flex: 1;
          }
        `}>
        <div css={sectionCss}>
          <img css={css `
              width: 100px;
            `} src='kabujukuTW/logo.png'/>
          <p css={headerCss}>股票操盤是一門技術！</p>
        </div>

        <div css={sectionCss}>
          <p css={headerCss}>聯絡我們</p>
          <AppLink href='https://goo.gl/maps/o4WEkkUBvmhgTQZ79' css={contactCss}>
            Japan 104-8139 東京都中央区銀座3-9-11 紙パルプ会館 9F
          </AppLink>
          <AppLink href='tel:03-6264-8419' css={contactCss}>
            03-6264-8419
          </AppLink>
          <AppLink href='mailto:information@kabu-juku.tw' css={contactCss}>
            information@kabu-juku.tw
          </AppLink>
          <AppLink href='https://kabujuku.tw' css={contactCss}>
            https://kabujuku.tw
          </AppLink>
        </div>
        <div css={sectionCss}>
          <p css={headerCss}>網站導航</p>
          <AppLink css={linkCss} href='https://www.aibashiromember.tw/'>
            相場師朗 官方網站
          </AppLink>
          <AppLink css={linkCss} href='https://www.aibashiromember.tw/'>
            相場師朗 股票道場 Facebook
          </AppLink>
          <AppLink css={linkCss} href='https://www.youtube.com/channel/UCwBRZHmKXDo30zwMM8mx7aw'>
            相場師朗 股票道場 YouTube
          </AppLink>
          <AppLink css={linkCss} href='https://line.me/R/ti/p/%40500wtdel'>
            相場師朗 股票道場 LINE@
          </AppLink>
        </div>
      </div>
      <div css={css `
          margin-top: ${isPc ? 24 : 123}px;
          font-size: 14px;
        `}>
        Copyright© 2022 株式会社FIRM BOND. All Rights Reserved.Design by EG.
      </div>
    </div>);
});
const sectionCss = css `
  ${flex.v.default};
  flex: 1;
  margin-bottom: 38px;
  ${createDesktopCss(css `
      margin: 0 72px;
      width: 248px;
    `)}
`;
const headerCss = css `
  ${fontWeight600};
  font-size: 16px;
  margin: 0;
  margin-bottom: 24px;
`;
const contactCss = css `
  ${baseNoLinkCss}
  font-size: 14px;
  margin: 0;
  margin-bottom: 12px;
`;
const linkCss = css `
  ${baseNoLinkCss};
  font-size: 14px;
  margin-bottom: 12px;
`;
