import React, { Fragment, memo } from 'react';
import { meCheckHandlerAgentWebStock } from '~/modules/SDK/me/meCheckHandlerAgentWeb';
import { UserAvatarAsDialogButton } from '~/modules/auth/components/UserAvatarAsDialogButton';
import { ChartServerSelect, ChartServerSelectClasses } from '~/modules/SDK/Chart2/ChartServerSelect';
import { useSnapshot } from 'valtio';
import { useSignalrStoreValueOHLC } from '~/modules/SDK/Signalr/useSignalrStoreValueOHLC';
import { useSignalrBuild } from '~/modules/SDK/Signalr/useSignalrBuild';
import { css, Global } from '@emotion/react';
import { flex, pureGrid } from '~/modules/AppLayout/FlexGridCss';
import { LayoutModule } from '~/modules/SDK/Layout/layout/LayoutModule';
import useMedia from '~/hooks/useMedia';
import { useKabujukuChart } from './modules/useKabujukuChart';
import { chartPositionButtonClasses } from '~/modules/cons-misc/mode-buttons/ChartPositionButton';
import { useStockPriceChangeDistributionState } from '~/pages/futuresai/stock-price-change-distribution/components/useStockPriceChangeDistributionState';
import RiseFallBar from '~/pages/futuresai/stock-price-change-distribution/components/RiseFallBar';
import { PageModeStore, pageModeButtonClasses, } from '~/modules/cons-misc/mode-buttons/PageModeButton';
import { AddSymbolButtonSmall } from '~/modules/WatchList/AddSymbolToWatchListButton';
import { SymbolName } from '~/modules/SDK/Symbol/SymbolName';
import { InvestmentConsultantSubscriptionCheck } from '~/modules/auth/components/UserSubscriptionCheckAsFullscreen';
import { useFirebaseValue } from '~/hooks/useFirebaseValue';
import IntradayTrendChart from '~/modules/trendChart';
import { SimpleQuoteListInteractWithChart, WatchListWithChart } from '~/modules/symbolQuote/simple';
import { useThemeStore } from '~/components/theme/useThemeStore';
import { useMeStore } from '~/modules/SDK/me/useMeStore';
import { InformationForm } from '~/modules/symbolQuote/information-form';
import { useKabujukuResource, } from '~/modules/screener/containers/useStockScreenerResource';
import { symbolGroupStore } from '~/modules/investment-consultant/SymbolGroupTable/symbolGroupStore';
import { Footer } from './modules/Footer';
import { kabujukuAvgLine } from '~/trades/indicators/kabujukuTW/kabujukuAvgLine';
import { Kabujuku, useKabujukuParamStore, } from '~/modules/screener/components/ScreenerConfig/Kabujuku';
const layout1 = new LayoutModule();
const layout2 = new LayoutModule();
layout1.store.Drawer.position = 'left';
layout2.store.Drawer.position = 'right';
layout1.store.Drawer.customCSS = css `
  .MuiPaper-root {
    width: 325px;
  }
`;
layout2.store.Drawer.customCSS = css `
  .MuiPaper-root {
    width: 325px;
  }
`;
layout1.store.DrawerToggle.customCSS = css `
  width: 40px;
  height: 40px;
`;
layout2.store.DrawerToggle.customCSS = css `
  width: 40px;
  height: 40px;
`;
layout2.store.LeftMainRight.customCSS = css `
  height: calc(100vh - 42px);
  grid-template-columns: 360px 1fr 360px;
  border: 1px solid #aaaaaa;
`;
layout2.store.Top.customCSS = css `
  justify-content: space-between;
`;
layout2.store.Left.customCSS = css `
  ${flex.h.default};
  height: calc(100vh - 42px);
  width: 100%;
  border: 1px solid #aaaaaa;
`;
layout2.store.Right.customCSS = css `
  ${flex.h.default};
  height: calc(100vh - 42px);
  width: 100%;
  border: 1px solid #aaaaaa;
`;
//layout top main footer 最外層
layout1.store.TopAndMainAndFooter.customCSS = css `
  width: 100%;
  height: 100%;
`;
layout1.store.Top.customCSS = css `
  justify-content: space-between;
  height: 42px;
  width: 100%;
  background-color: #111111;
`;
layout1.store.Main.customCSS = css `
  ${layout1.store.Main.defaultsCSS};

  .charting_library {
    height: calc(100% - 6px);
  }
`;
layout1.store.Footer.customCSS = css `
  width: 100%;
  background-color: #111111;
`;
export const SideBarCss = css `
  ${flex.v.crossCenter};
  width: 100%;
  height: 100%;
  border-radius: 5px;
  font-size: 18px;
  padding: 2.5px;
`;
const chartPositionCss = css `
  ${flex.h.crossCenter};
  width: 100%;
  height: 30px;
  font-size: 16px;
  .${chartPositionButtonClasses.default} {
    &:nth-of-type(1) {
      border: 1px solid #555555;
      background-color: #550000;
    }
  }
  .${chartPositionButtonClasses.selected} {
    &:nth-of-type(1) {
      border: 1px solid #eeeeee;
      background-color: #bb0000;
    }
  }
  .${chartPositionButtonClasses.default} {
    &:nth-of-type(2) {
      border: 1px solid #555555;
      background-color: #555555;
    }
  }
  .${chartPositionButtonClasses.selected} {
    &:nth-of-type(2) {
      border: 1px solid #eeeeee;
      background-color: #888888;
    }
  }
  .${chartPositionButtonClasses.default} {
    &:nth-of-type(3) {
      border: 1px solid #555555;
      background-color: #005500;
    }
  }

  .${chartPositionButtonClasses.selected} {
    &:nth-of-type(3) {
      border: 1px solid #eeeeee;
      background-color: #00bb00;
    }
  }
`;
const dataChartCss = css `
  width: 100%;
  height: 70px;
  background-color: #555555;
`;
const rankingCss = css `
  ${flex.v.crossCenter};
  width: 100%;
  height: calc(100% - 420px);
`;
const leftContentCss = css `
  ${flex.v.crossCenter};
  width: 100%;
  height: 250px;
`;
export const pageModeCss = css `
  ${flex.h.allCenter};
  width: 100%;
  height: 30px;
  margin-top: 5px;
  color: #1e111c;
  .${pageModeButtonClasses.default} {
    color: #1e111c;
    background: linear-gradient(0deg, #f3fafc, #f3fafc);
    border: 1px solid #555555;
    &:hover {
      background: linear-gradient(150deg, #f3fafc, #f3fafc);
    }
  }
  .${pageModeButtonClasses.selected} {
    background: linear-gradient(180deg, #813ec1, #49116b);
    border: 1px solid #555555;
  }
`;
const watchlistAddCss = css `
  ${flex.h.allCenter};
  ${pureGrid};
  width: 100%;
  height: 40px;
  grid-template-columns: 40% 60%;
  padding: 5px;
`;
const dayTrendTitle = css `
  ${flex.h.allCenter};
  width: 100%;
  height: 30px;
`;
const dayTrendSymbol = css `
  width: 100%;
  height: calc(50% - 60px);
  margin-bottom: 5px;
`;
const symbolNameTitle = css `
  width: 100%;
  hieght: 40px;
  color: #333333;
  font-size: 16px;
  padding: 10px;
  ${flex.h.crossCenter}
`;
const rightPageContentCss = css `
  width: 100%;
  height: calc(100% - 500px);
`;
export const SideBarTitleCss = css `
  ${flex.h.allCenter};
  width: 100%;
  height: 30px;
  color: #ffffff;
  background-color: #6d34a0;
  font-size: 16px;
  border-radius: 5px;
  margin: 2.5px 0px;
`;
symbolGroupStore.volumeLimit = 0;
const indicators = [kabujukuAvgLine];
/** 左側會員資料與伺服器按鈕 先醜醜的留著 */
export const UserDashBord = memo(function UserDashBord() {
    //--
    const meFirebaseState = useMeStore(meState => meState.meFirebaseState);
    const meUserState = useMeStore(meState => meState.meUserState);
    //--
    return (<div css={css `
        ${flex.h.allCenter};
        ${pureGrid};
        grid-template-columns: 40% 60%;
        width: 100%;
        height: 60px;
        padding: 0px 8px;
        border-radius: 5px;
        color: #222222;
        background-color: #efefef;
      `}>
      <div css={css `
          ${flex.h.allCenter};
        `}>
        <UserAvatarAsDialogButton />
        <div css={css `
            ${flex.v.allCenter};
            padding: 0px 8px;
          `}>
          <div css={css `
              color: #222222;
              font-size: 14px;
            `}>
            {meFirebaseState?.name}
          </div>
          <div css={css `
              color: #555555;
              font-size: 12px;
            `}>
            {meUserState?.code}
          </div>
        </div>
      </div>

      <ChartServerSelect useChart={useKabujukuChart} css={css `
          .${ChartServerSelectClasses.button} {
            background-color: #aaaaaa;
          }
        `}/>
    </div>);
});
/** 漲跌家數 先留著看老師要不要 */
const DataChart = memo(function DataChart_() {
    const { state: { overallCount, fallCount, flatCount, riseCount, vBar }, } = useStockPriceChangeDistributionState.useContainer();
    return (<div css={css `
        height: 100%;
        width: 100%;
        font-size: 16px;
      `}>
      <RiseFallBar all={overallCount} rise={riseCount} fall={fallCount} flat={flatCount}/>
    </div>);
});
/** 老師自選的商品清單 先留著 */
export const FirebaseSymbolGroup = memo(function FirebaseSymbol_() {
    const long = useFirebaseValue('lung88988_signal', 'long');
    return (<SimpleQuoteListInteractWithChart data={long?.symbols ?? []} indicators={indicators} chart={useKabujukuChart}/>);
});
const LeftSidebar = memo(function LeftSidebar_() {
    const kabujukuParamState = useSnapshot(useKabujukuParamStore);
    const { join } = kabujukuParamState;
    const selectedIndicators = Object.entries(kabujukuParamState.indicators)
        .filter(([_, v]) => v)
        .map(([k, _]) => k);
    const { data: symbols } = useKabujukuResource({
        date: '2022-07-06',
        join,
        indicators: selectedIndicators,
    });
    return (<div css={SideBarCss}>
      <div css={css `
          ${dayTrendTitle};
          background-color: #d8d8d8;
        `}>
        條件篩選表
      </div>
      <div css={css `
          ${dayTrendSymbol}
          height: 180px;
        `}>
        <Kabujuku />
      </div>
      <div css={css `
          ${dayTrendTitle};
          background-color: #d8d8d8;
        `}>
        選股結果
      </div>
      <div css={css `
          ${dayTrendSymbol}
          height: calc(100% - 250px);
        `}>
        <SimpleQuoteListInteractWithChart data={symbols ?? []} indicators={indicators} chart={useKabujukuChart}/>
      </div>
    </div>);
});
PageModeStore.buttonMode = 'userStock';
const RightSidebar = memo(function RightSidebar_() {
    const state = useSnapshot(PageModeStore);
    const symbolNumber = useKabujukuChart((state_) => state_.symbol);
    return (<div css={SideBarCss}>
      <UserDashBord />
      <div css={symbolNameTitle}>
        {symbolNumber}
        <SymbolName symbol={symbolNumber}/>
      </div>
      <div css={css `
          width: 100%;
          height: 180px;
        `}>
        <IntradayTrendChart symbol={symbolNumber} ticksSize={14} ticksHeight={20} priceTicksMargin={2.5} priceTicksSize={14}/>
      </div>
      <InformationForm symbol={symbolNumber}/>
      <div css={watchlistAddCss}>
        <div css={css `
            ${flex.h.allCenter}
            color:#222222;
          `}>
          自選股狀態
        </div>
        <AddSymbolButtonSmall groupName={'goldbrain_stock_group_1'} symbolNumber={symbolNumber} listSize={50}/>
      </div>
      <div css={css `
          ${dayTrendTitle};
          background-color: #d8d8d8;
        `}>
        自選股
      </div>
      <div css={rightPageContentCss}>
        {state.buttonMode === 'fireBaseStock' && <FirebaseSymbolGroup />}
        {state.buttonMode === 'userStock' && (<WatchListWithChart groupName={'goldbrain_stock_group_1'} useChart={useKabujukuChart} indicators={indicators}/>)}
      </div>
    </div>);
});
const KabujukuTWNextPage = () => {
    const legitUser = meCheckHandlerAgentWebStock.useCheck();
    const { isPc } = useMedia();
    useSignalrBuild({ reconnectCheckInterval: 10000 });
    useSignalrStoreValueOHLC.getState().useValueUpdateInterval(500);
    useThemeStore.setState({ theme: 'light' });
    return (<Fragment>
      <Global styles={css `
          html,
          body,
          #__next {
            background-color: #ffffff;
          }
        `}></Global>
      {!legitUser ? (<InvestmentConsultantSubscriptionCheck show={!legitUser}/>) : (<div css={css `
            ${flex.h.allCenter};
          `}>
          <layout1.Drawer>
            <layout1.DrawerToggle />
            {!isPc && <LeftSidebar />}
          </layout1.Drawer>
          <layout2.Drawer>
            <layout1.DrawerToggle />
            {!isPc && <RightSidebar />}
          </layout2.Drawer>

          <layout1.TopAndMainAndFooter>
            {!isPc && (<layout1.Top>
                <layout1.DrawerToggle />
                <layout2.DrawerToggle />
              </layout1.Top>)}
            {isPc && (<layout1.Top>
                <img css={css `
                    width: 100px;
                  `} src='kabujukuTW/logo.png'/>
                股票操盤是一門技術！
              </layout1.Top>)}
            <layout1.Main>
              <layout2.LeftMainRight>
                {isPc && (<layout2.Left>
                    <LeftSidebar />
                  </layout2.Left>)}
                <layout2.Main>
                  <useKabujukuChart.Chart darkmode={false}/>
                </layout2.Main>

                {isPc && (<layout2.Right>
                    <RightSidebar />
                  </layout2.Right>)}
              </layout2.LeftMainRight>
            </layout1.Main>

            <layout1.Footer>
              <Footer />
            </layout1.Footer>
          </layout1.TopAndMainAndFooter>
        </div>)}
    </Fragment>);
};
export default KabujukuTWNextPage;
