import { createChartStore } from '~/modules/SDK/Chart2/createChartStore';
import { kabujukuAvgLine } from '~/trades/indicators/kabujukuTW/kabujukuAvgLine';
const preparedIndicators = [kabujukuAvgLine];
const renderIndicators = [kabujukuAvgLine];
export const useKabujukuChart = createChartStore({
    preparedCustomIndicators: preparedIndicators,
    defaultsOptions: {
        customIndicators: renderIndicators,
        enableVolumeIndicator: false,
        disabledHeaderWidget: false,
        disabledLeftToolbar: false,
        disabledTimeframesToolbar: true,
        symbol: 'TSEA',
        interval: '1D',
        client_id: '',
        overrides: {
            'mainSeriesProperties.candleStyle.borderDownColor': '#111111',
            'mainSeriesProperties.candleStyle.borderUpColor': '#111111',
            'mainSeriesProperties.candleStyle.downColor': '#111111',
            'mainSeriesProperties.candleStyle.upColor': '#ffffff',
            'mainSeriesProperties.candleStyle.wickDownColor': '#444444',
            'mainSeriesProperties.candleStyle.wickUpColor': '#444444',
            'mainSeriesProperties.candleStyle.drawBorder': true,
            'mainSeriesProperties.showPriceLine': false,
            'mainSeriesProperties.showCountdown': true,
            'paneProperties.horzGridProperties.style': 1,
            'paneProperties.vertGridProperties.style': 1,
            'paneProperties.topMargin': 15,
            'paneProperties.bottomMargin': 15,
            'paneProperties.vertGridProperties.color': '#555555',
            'paneProperties.horzGridProperties.color': '#555555',
            'paneProperties.background': '#fafafa',
            'timeScale.rightOffset': 0,
            'scalesProperties.fontSize': 14,
            'scalesProperties.textColor': '#222222',
            'scalesProperties.lineColor': '#222222',
        },
    },
});
