import { createIndicator } from '~/modules/SDK/indicator/createIndicator';
export const kabujukuAvgLine = createIndicator({
    displayName: '移動平均線',
    id: 'kabujukuAvgLine',
    constructorScope: {
        init(context, inputCallback) {
            return;
        },
        main(context, inputCallback) {
            const in1 = 3;
            const in2 = 5;
            const in3 = 7;
            const in4 = 10;
            const in5 = 20;
            const in6 = 30;
            const in7 = 50;
            const in8 = 100;
            const in9 = 150;
            const in10 = 300;
            const close = this.PineJS.Std.close(this._context);
            const close_array = this._context.new_var(close);
            const sma1 = this.PineJS.Std.sma(close_array, in1, this._context);
            const sma2 = this.PineJS.Std.sma(close_array, in2, this._context);
            const sma3 = this.PineJS.Std.sma(close_array, in3, this._context);
            const sma4 = this.PineJS.Std.sma(close_array, in4, this._context);
            const sma5 = this.PineJS.Std.sma(close_array, in5, this._context);
            const sma6 = this.PineJS.Std.sma(close_array, in6, this._context);
            const sma7 = this.PineJS.Std.sma(close_array, in7, this._context);
            const sma8 = this.PineJS.Std.sma(close_array, in8, this._context);
            const sma9 = this.PineJS.Std.sma(close_array, in9, this._context);
            const sma10 = this.PineJS.Std.sma(close_array, in10, this._context);
            return [sma1, sma2, sma3, sma4, sma5, sma6, sma7, sma8, sma9, sma10];
        },
    },
    metainfo: {
        //宣告
        plots: [
            {
                id: 'plot_0',
                type: 'line',
            },
            {
                id: 'plot_1',
                type: 'line',
            },
            {
                id: 'plot_2',
                type: 'line',
            },
            {
                id: 'plot_3',
                type: 'line',
            },
            {
                id: 'plot_4',
                type: 'line',
            },
            {
                id: 'plot_5',
                type: 'line',
            },
            {
                id: 'plot_6',
                type: 'line',
            },
            {
                id: 'plot_7',
                type: 'line',
            },
            {
                id: 'plot_8',
                type: 'line',
            },
            {
                id: 'plot_9',
                type: 'line',
            },
        ],
        defaults: {
            //樣式調整
            styles: {
                plot_0: {
                    linestyle: 0,
                    linewidth: 2,
                    plottype: 0,
                    trackPrice: !1,
                    transparency: 0,
                    visible: !1,
                    color: '#da9dc6',
                },
                plot_1: {
                    linestyle: 0,
                    linewidth: 2,
                    plottype: 0,
                    trackPrice: !1,
                    transparency: 0,
                    visible: !0,
                    color: '#ea3323',
                },
                plot_2: {
                    linestyle: 0,
                    linewidth: 2,
                    plottype: 0,
                    trackPrice: !1,
                    transparency: 0,
                    visible: !1,
                    color: '#000000',
                },
                plot_3: {
                    linestyle: 0,
                    linewidth: 2,
                    plottype: 0,
                    trackPrice: !1,
                    transparency: 0,
                    visible: !0,
                    color: '#296218',
                },
                plot_4: {
                    linestyle: 0,
                    linewidth: 2,
                    plottype: 0,
                    trackPrice: !1,
                    transparency: 0,
                    visible: !1,
                    color: '#7e8c4c',
                },
                plot_5: {
                    linestyle: 0,
                    linewidth: 2,
                    plottype: 0,
                    trackPrice: !1,
                    transparency: 0,
                    visible: !0,
                    color: '#1e3dec',
                },
                plot_6: {
                    linestyle: 0,
                    linewidth: 2,
                    plottype: 0,
                    trackPrice: !1,
                    transparency: 0,
                    visible: !0,
                    color: '#6d46ec',
                },
                plot_7: {
                    linestyle: 0,
                    linewidth: 2,
                    plottype: 0,
                    trackPrice: !1,
                    transparency: 0,
                    visible: !1,
                    color: '#cac23f',
                },
                plot_8: {
                    linestyle: 0,
                    linewidth: 2,
                    plottype: 0,
                    trackPrice: !1,
                    transparency: 0,
                    visible: !1,
                    color: '#56473b',
                },
                plot_9: {
                    linestyle: 0,
                    linewidth: 2,
                    plottype: 0,
                    trackPrice: !1,
                    transparency: 0,
                    visible: !1,
                    color: '#d78f60',
                },
            },
        },
        styles: {
            plot_0: {
                title: '3日',
                histogramBase: 0,
            },
            plot_1: {
                title: '5日',
                histogramBase: 0,
            },
            plot_2: {
                title: '7日',
                histogramBase: 0,
            },
            plot_3: {
                title: '10日',
                histogramBase: 0,
            },
            plot_4: {
                title: '20日',
                histogramBase: 0,
            },
            plot_5: {
                title: '30日',
                histogramBase: 0,
            },
            plot_6: {
                title: '50日',
                histogramBase: 0,
            },
            plot_7: {
                title: '100日',
                histogramBase: 0,
            },
            plot_8: {
                title: '150日',
                histogramBase: 0,
            },
            plot_9: {
                title: '300日',
                histogramBase: 0,
            },
        },
        is_price_study: !0,
        inputs: [],
        scriptIdPart: '',
    },
});
