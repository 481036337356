import { memo } from 'react';
import { css } from '@emotion/react';
import { globalRed, globalGrey, globalGreen } from '~/modules/AppLayout/Colors';
import { flex, jc } from '~/modules/AppLayout/FlexGridCss';
const defaultBarCss = css `
  border-radius: 2px;
  height: 24px;
  transition-property: width;
  transition-duration: 0.5s;
`;
const RiseFallBar = memo(function RiseFallBar({ rise, fall, flat, all }) {
    return (<div>
        <div css={css `
            ${flex.h.allCenter}
            width: 100%;
            color: white;
            gap: 8px;
          `}>
          <div css={css `
              width: ${(800 * fall) / all}px;
              background: ${globalGreen.g400};
              ${defaultBarCss}
            `}/>

          <div css={css `
              width: ${(800 * flat) / all}px;
              background: ${globalGrey.g200};
              ${defaultBarCss}
            `}/>
          <div css={css `
              width: ${(800 * rise) / all}px;
              background: ${globalRed.r600};
              ${defaultBarCss}
            `}/>
        </div>

        <div css={css `
            ${flex.h.default};
            ${jc.spaceBetween};
            width: 100%;
            gap: 8px;
            letter-spacing: -1px;
            margin-top: 8px;
          `}>
          <div>
            下跌家數：{fall} ({((fall / all) * 100).toFixed(0)}%)
          </div>
          <div>
            平盤家數：{flat} ({((flat / all) * 100).toFixed(0)}%)
            <h6 css={css `
                color: ${globalGrey.g600};
              `}>
              ※ 當日漲跌幅 ± 0.5% 以內視為平盤
            </h6>
          </div>
          <div>
            上漲家數：{rise} ({((rise / all) * 100).toFixed(0)}%)
          </div>
        </div>
      </div>);
});
export default RiseFallBar;
